import { useQuery, useQueryClient, useMutation } from 'react-query'
import { get, post, patch } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { standardDate, getTimeFromDate } from '@/util/dateTime'
import { getAccessToken } from '@/util/jwtTokens'

export const useRoomCode = ({ batchId, type = 'room', limit = 30 }) => {
  const queryClient = useQueryClient()

  function allValuesPresent(obj) {
    return Object.values(obj).every(
      (value) => value !== null && value !== undefined
    )
  }

  const { data, isLoading, isError, error } = useQuery(
    ['roomCodes', batchId, type],
    async () => {
      const token = await getAccessToken()
      const { results: roomCodes } = await get(
        apiUrl('batches') + `/${batchId}/test-codes`,
        { token, params: { limit, type } }
      )
      return roomCodes
    },
    {
      enabled: !!batchId,
    }
  )

  const createRoomCodeMutation = useMutation(
    async ({ roomCodeParams }) => {
      if (!allValuesPresent(roomCodeParams))
        throw Error('Not all parameters supplied')
      const {
        maxParticipants,
        startDate,
        startTime,
        endDate,
        endTime,
        type = 'room',
      } = roomCodeParams
      const body = {
        maxParticipants,
        start: new Date(
          `${standardDate(startDate)} ${getTimeFromDate(startTime)}`
        ),
        end: new Date(`${standardDate(endDate)} ${getTimeFromDate(endTime)}`),
        type,
      }
      const token = await getAccessToken()
      const newRoomCode = await post(
        apiUrl('batches') + `/${batchId}/test-codes`,
        body,
        { token }
      )
      return newRoomCode
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roomCodes', batchId, type])
      },
    }
  )

  const updateRoomCodeMutation = useMutation(
    async ({ id, fields }) => {
      const token = await getAccessToken()
      const returnedCode = await patch(apiUrl('testCodes') + `/${id}`, fields, {
        token,
      })
      return returnedCode
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roomCodes', batchId, type])
      },
    }
  )

  return {
    data,
    isLoading,
    isError,
    error,
    createRoomCodeMutation,
    updateRoomCodeMutation,
  }
}
