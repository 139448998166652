import { useEffect, useCallback } from 'react'
import { get } from '@/util/api'
import { useStore } from '@/util/store'
import { setAccessToken, setRefreshToken } from '@/util/jwtTokens'

/**
 * Authenticate current user against server session.
 */
export function useUser() {
  const user = useStore((state) => state.user)
  const setUser = useStore((state) => state.setUser)
  const isLoggedIn =
    user && Object.keys(user).length && user.constructor === Object

  const fetchUser = useCallback(async () => {
    if (isLoggedIn) return

    const user = await get('/api/user')
    setAccessToken(user.token)
    setRefreshToken(user.refresh)
    setUser(user)
  }, [isLoggedIn, setUser])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return user
}
