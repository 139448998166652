import { testProperties } from '@/util/consts'

export function orderTests(a, b) {
  // used to sort tests until backend ordering is implemented
  const aID = a.hasOwnProperty('test')
    ? a.test.internalTestIdentifier
    : a.internalTestIdentifier
  const bID = b.hasOwnProperty('test')
    ? b.test.internalTestIdentifier
    : b.internalTestIdentifier

  return testProperties[aID].order - testProperties[bID].order
}

export function orderMenuOptions(a, b) {
  return (
    testProperties[a.internalTestIdentifier].order -
    testProperties[b.internalTestIdentifier].order
  )
}
