import { Icon } from '@/components/icon'
import classnames from '@/util/classnames'

import styles from './admin-notification-banner.module.scss'
import {
  useNotifications,
  notificationTypes,
} from '@/util/hooks/useNotifications'
import { useState } from 'react'

const MaintenanceBanner = ({ canDisplay }) => {
  const { isLoading, notification } = useNotifications(
    notificationTypes.ADMIN_BANNER
  )

  const shouldDisplay = !isLoading && notification
  if (!shouldDisplay || !canDisplay) return null

  return (
    <div className={classnames([styles.banner])}>
      <p
        className={classnames([styles.content])}
        dangerouslySetInnerHTML={{
          __html: notification,
        }}
      ></p>
    </div>
  )
}

const FeatureBanner = ({ onNotificationChange }) => {
  const { isLoading, notification, dismissNotification } = useNotifications(
    notificationTypes.FEATURE_BANNER
  )

  const shouldDisplay = !isLoading && notification && notification.content

  if (shouldDisplay) {
    onNotificationChange(true)
  } else {
    onNotificationChange(false)
    return null
  }

  const { content, id } = notification

  return (
    <div className={classnames([styles.banner, styles.feature])}>
      <p
        className={classnames([styles.content])}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></p>
      <button
        onClick={() => dismissNotification.mutate(id)}
        className={classnames([styles['close-btn']])}
      >
        <Icon name='close' />
      </button>
    </div>
  )
}

export const AdminNotificationBanner = () => {
  // feature notifications take precedence over maintenance
  const [notifyFeature, setNotifyFeature] = useState(false)

  return (
    <div>
      <MaintenanceBanner canDisplay={!notifyFeature} />
      <FeatureBanner
        onNotificationChange={(hasNotification) =>
          setNotifyFeature(hasNotification)
        }
      />
    </div>
  )
}
