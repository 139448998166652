import { useQuery, useMutation, useQueryClient } from 'react-query'
import produce from 'immer'

import { get, post } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { getAccessToken } from '@/util/jwtTokens'

export const notificationTypes = {
  ADMIN_BANNER: 'ab',
  ADMIN_MODAL: 'am',
  FEATURE_BANNER: 'fb',
  RESPONDENT_MODAL: 'rm',
}

// Back end notification types
const MAINTENANCE = 'Maintenance'
const FEATURE = 'Feature'

export const useNotifications = (notificationType) => {
  const queryClient = useQueryClient()
  const { isLoading, data: notification, refetch } = useQuery(
    'notifications',
    async () => {
      const token = await getAccessToken()
      return get(apiUrl('notifications'), { token })
    },
    {
      staleTime: 15000,
      select: (notifications) => {
        if (notificationType === notificationTypes.ADMIN_BANNER) {
          const adminBanners = notifications.results.filter(
            (n) =>
              n.notificationType === MAINTENANCE && n.adminBannerContent.trim()
          )

          return adminBanners.length ? adminBanners[0].adminBannerContent : null
        }

        if (notificationType === notificationTypes.ADMIN_MODAL) {
          const adminModals = notifications.results.filter(
            (n) =>
              n.notificationType === MAINTENANCE && n.adminModalContent.trim()
          )

          return adminModals.length ? adminModals[0].adminModalContent : null
        }

        if (notificationType === notificationTypes.FEATURE_BANNER) {
          const featureBanners = notifications.results.filter(
            (n) =>
              n.notificationType === FEATURE &&
              n.adminBannerContent.trim() &&
              !n.isDismissed
          )

          return featureBanners.length
            ? {
                content: featureBanners[0].adminBannerContent,
                id: featureBanners[0].id,
              }
            : null
        }

        if (notificationType === notificationTypes.RESPONDENT_MODAL) {
          const respondentModals = notifications.results.filter(
            (n) =>
              n.notificationType === MAINTENANCE &&
              n.respondentModalContent.trim()
          )

          return respondentModals.length
            ? respondentModals[0].respondentModalContent
            : null
        }
      },
    }
  )

  const dismissNotification = useMutation(
    async (id) => {
      const token = await getAccessToken()

      return post(apiUrl('dismissNotification', { args: { id } }), null, {
        token,
      })
    },
    {
      onMutate: async (id) => {
        // https://tanstack.com/query/v3/docs/framework/react/guides/optimistic-updates
        await queryClient.cancelQueries('notifications')
        const previousNotifications = queryClient.getQueryData('notifications')
        queryClient.setQueryData('notifications', (old) => {
          return produce(old, (draft) => {
            const targetNotification = draft.results.find((n) => n.id === id)
            if (targetNotification) targetNotification.isDismissed = true
          })
        })
        return { previousNotifications }
      },

      onError: (err, response, context) => {
        queryClient.setQueryData('notifications', context.previousNotifications)
      },

      onSettled: () => {
        queryClient.invalidateQueries('notifications')
      },
    }
  )

  return {
    isLoading,
    notification,
    refetch,
    dismissNotification,
  }
}
