import { useRouter } from 'next/router'
import { useStore } from '@/util/store'
import { useIdleTimer } from 'react-idle-timer'
import { ADMIN_TIMEOUT_TIME } from '../consts'

export const useAdminTimeout = () => {
  // not in active use (redundant after token refresh work)
  // delete if nothing comes up during QA
  const router = useRouter()
  const setUser = useStore((state) => state.setUser)
  const handleTimeout = async () => {
    try {
      setUser({})
      await fetch('/api/logout')
      router.push('/login')
      window.location.reload()
    } catch (error) {
      console.log(error)
      setTimeout(handleTimeout, 5000)
    }
  }

  useIdleTimer({
    name: 'adminTimeout',
    timeout: ADMIN_TIMEOUT_TIME * 60000,
    onIdle: handleTimeout,
    crossTab: true,
    onMessage: handleTimeout,
  })

  return { handleTimeout }
}
