import { jwtDecode } from 'jwt-decode'
import { apiUrl } from '@/util/urls'

let tokenPromise = null
const ACCESS_KEY = 'access'
const REFRESH_KEY = 'refresh'

export const isJWTExpired = (jwt) => {
  if (!jwt) return true

  // buffer time before expiry to avoid using tokens that would expire mid-flight
  const EXPIRY_BUFFER = 30000

  try {
    const { exp } = jwtDecode(jwt)
    if (Date.now() + EXPIRY_BUFFER >= exp * 1000) {
      return true
    }
  } catch (err) {
    // couldn't parse the token, assume it's done
    return true
  }

  return false
}

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_KEY, token)
}

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_KEY, token)
}

export const getAccessToken = async () => {
  let retries = 0

  const getToken = async () => {
    if (retries > 2) {
      return ''
    }

    const accessToken = localStorage.getItem(ACCESS_KEY)
    const refreshToken = localStorage.getItem(REFRESH_KEY)

    if (!isJWTExpired(accessToken)) {
      // token is good to go
      return accessToken
    }

    if (tokenPromise && retries === 0) {
      // already fetching the token
      return tokenPromise
    }

    if (isJWTExpired(refreshToken)) {
      // can't refresh, it's over chief
      return ''
    }

    // try refreshing
    tokenPromise = fetch(apiUrl('refreshToken'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    })
      .then((response) => response.json())
      .then(({ access, refresh }) => {
        // update user tokens in Next session
        return fetch('/api/user', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: access,
            refresh,
          }),
        }).then(() => {
          setAccessToken(access)
          setRefreshToken(refresh)
          return access
        })
      })
      .catch((e) => {
        console.log(e)
        retries++
        return getToken()
      })
      .finally(() => (tokenPromise = null))

    return tokenPromise
  }

  return getToken()
}
