import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useState } from 'react'
import produce from 'immer'

import { get, patch, post, del } from '@/util/api'
import { apiUrl } from '@/util/urls'
import { getAccessToken } from '@/util/jwtTokens'

export function useOrganization() {
  const queryClient = useQueryClient()
  const [usersWaitingActivation, setUsersWaitingActivation] = useState([])

  const { isLoading: isOrgLoading, data: organization } = useQuery(
    'organization',
    async () => {
      const token = await getAccessToken()
      return get(apiUrl('organization'), { token })
    }
  )

  const { data: teamMembers } = useQuery(
    'teamMembers',
    async () => {
      const token = await getAccessToken()
      return get(apiUrl('teammembers') + '/', { token })
    },
    {
      onSuccess: (data) => {
        const waitingUsers = data.filter(
          (member) =>
            member.roleInfo?.isWaitingForActivation && member.isSignedUp
        )
        setUsersWaitingActivation(waitingUsers)
      },
    }
  )

  const setOrganization = (newOrganization) => {
    queryClient.setQueryData('organization', newOrganization)
  }

  const setTeamMembers = (newTeamMembers) => {
    queryClient.setQueryData('teamMembers', newTeamMembers)
  }

  const updateOrgMemberMutation = useMutation(
    async ({ body, id }) => {
      const token = await getAccessToken()
      return patch(apiUrl('teammembers') + `/${id}/`, body, { token })
    },
    {
      onSuccess: (data) => {
        addOrUpdateOrgMember(data)
      },
    }
  )
  const updateOrgMember = ({ id, body, onSuccess, onError }) =>
    updateOrgMemberMutation.mutate({ id, body }, { onSuccess, onError })

  const inviteOrgMemberMutation = useMutation(
    async (body) => {
      const token = await getAccessToken()
      return post(apiUrl('teamInvite'), body, { token })
    },
    {
      onSuccess: (data) => {
        addOrUpdateOrgMember(data)
      },
    }
  )
  const inviteOrgMember = ({ body, onSuccess, onError }) =>
    inviteOrgMemberMutation.mutate(body, { onSuccess, onError })

  const deleteOrgMemberMutation = useMutation(
    async ({ id }) => {
      const token = await getAccessToken()
      return del(apiUrl('teammembers') + `/${id}/`, { token })
    },
    {
      onSuccess: (data, variables) => {
        removeOrgMember(variables.id)
      },
    }
  )
  const deleteOrgMember = ({ body, onSuccess, onError }) =>
    deleteOrgMemberMutation.mutate(body, { onSuccess, onError })

  function addOrUpdateOrgMember(member) {
    const updatedOrg = produce(organization, (draft) => {
      const mbrIdx = draft.users.findIndex((user) => user.id === member.id)
      if (mbrIdx === -1) {
        draft.users.push(member)
      } else {
        draft.users[mbrIdx] = member
      }
    })
    const updatedTeamMembers = produce(teamMembers, (draft) => {
      const mbrIdx = draft.findIndex((user) => user.id === member.id)
      if (mbrIdx === -1) {
        draft.push(member)
      } else {
        draft[mbrIdx] = member
      }
    })
    setTeamMembers(updatedTeamMembers)
    setOrganization(updatedOrg)
  }

  function removeOrgMember(mbrId) {
    const updatedOrg = produce(organization, (draft) => {
      const mbrIdx = draft.users.findIndex((user) => user.id === mbrId)
      draft.users.splice(mbrIdx, 1)
    })
    const updatedTeam = produce(teamMembers, (draft) => {
      const mbrIdx = draft.findIndex((user) => user.id === mbrId)
      draft.splice(mbrIdx, 1)
    })
    setTeamMembers(updatedTeam)
    setOrganization(updatedOrg)
  }

  return {
    organization,
    isOrgLoading,
    teamMembers,
    updateOrgMember,
    inviteOrgMember,
    deleteOrgMember,
    usersWaitingActivation,
  }
}
