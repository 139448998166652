import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { TextInput } from '@/components/text-input'
import { showNotification } from '@/components/notification'
import { Button } from '@/components/button'
import { Modal } from '.'
import { apiUrl } from '@/util/urls'
import { patch } from '@/util/api'
import { useStore } from '@/util/store'
import { passwordMatch } from '@/util/validation'
import dynamic from 'next/dynamic'
import { getAccessToken } from '@/util/jwtTokens'

const PasswordChecklist = dynamic(() => import('react-password-checklist'), {
  ssr: false,
})

export function ChangePasswordModal() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })
  const closeModal = useStore((state) => state.closeModal)
  const [isValidPassword, setIsValidPassword] = useState(false)

  const currentPassword = watch('currentPassword')
  const newPassword = watch('newPassword')
  const confirmPassword = watch('confirmPassword')

  const handlePasswordChange = async (current_password, new_password) => {
    try {
      const token = await getAccessToken()
      await patch(
        apiUrl('changePassword'),
        { current_password, new_password },
        { token }
      )
      showNotification({
        message: 'Password successfully changed.',
        type: 'success',
      })
      closeModal()
    } catch (error) {
      if (error.code === 400) {
        showNotification({
          message:
            'The current password you entered is incorrect. Please try again.',
        })
      }
      console.error('ERROR: ', error)
    }
  }

  const onSubmit = async (data) => {
    await handlePasswordChange(data.currentPassword, data.newPassword)
  }

  const onError = (errors) => {
    let errorMsg = 'Please correct the highlighted fields below.'
    if (
      errors.confirmPassword &&
      errors.confirmPassword.type === 'passwordMatch'
    ) {
      errorMsg =
        "Passwords don't match. Please re-enter the password confirmation to match the new password."
    }
    showNotification({ message: errorMsg })
  }

  return (
    <Modal variant='medium'>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className='modal-content'>
          <h1>Change Password</h1>
          <TextInput
            name='currentPassword'
            type='password'
            label='Current Password'
            placeholder=''
            register={register}
            required
            isPasswordField
          />
          <TextInput
            name='newPassword'
            type='password'
            label='New Password'
            placeholder=''
            register={register}
            required
            isPasswordField
          />
          <PasswordChecklist
            style={{ fontSize: 13.5, marginBottom: 24 }}
            rules={['minLength', 'specialChar', 'number', 'capital']}
            minLength={8}
            value={newPassword}
            messages={{
              minLength: '8 characters min',
              specialChar: 'a special character, ex: @&$!*',
              number: 'a number',
              capital: 'a captital letter',
            }}
            iconSize={12}
            className='password-checklist'
            onChange={(isValid) => {
              setIsValidPassword(isValid)
            }}
          />
          <TextInput
            name='confirmPassword'
            type='password'
            label='Confirm New Password'
            placeholder=''
            register={register}
            required
            minLength={8}
            isPasswordField
            errors={errors}
            validate={{
              passwordMatch: (v) => passwordMatch(v, getValues),
            }}
          />
        </div>
        <div className='modal-actions'>
          <button
            type='button'
            onClick={closeModal}
            className='btn btn-outline'
          >
            Cancel
          </button>
          <Button
            disabled={
              !currentPassword ||
              !isValidPassword ||
              !confirmPassword ||
              isSubmitting
            }
            className='btn'
            isSubmitting={isSubmitting}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}
