import { useEffect, useState, useCallback } from 'react'
import { saveAs } from 'file-saver'
import { useQueryClient } from 'react-query'

import { showNotification } from '@/components/notification'

import { apiUrl } from '@/util/urls'
import { get, post } from '@/util/api'
import { useRespondents } from '@/util/hooks'
import {
  inProgressReportStatuses,
  reportStatuses,
  reportTypes,
  testIdentifiers,
} from '@/util/consts'
import { standardDate } from '../dateTime'
import Link from 'next/link'
import { getAccessToken } from '@/util/jwtTokens'

export function useReport({
  batchId,
  reportType,
  testId,
  respondents,
  isDisabled = false,
}) {
  /**
   * Hook used to manage the API calls for generating a report and communicating its status
   *
   * @param reportType: string,
   * @param testId: string, corresponds to an internal test id - either 'MAB_II' or 'NEO_B'
   */
  const [status, setStatus] = useState(reportStatuses.processing)
  const url = `${apiUrl(
    'report'
  )}/${batchId}?output=${reportType}&test=${testId}`
  const queryClient = useQueryClient()
  const { refreshRespondents, refetchRespondents } = useRespondents({})

  useEffect(() => {
    if (isDisabled) {
      setStatus(reportStatuses.awaitingScoring)
      return
    }

    // set initial report status
    const setCsvStatus = async () => {
      const status = await _getCsvStatus()
      setStatus(status)
    }
    if (reportType === reportTypes.CSV) {
      setCsvStatus()
    }
  }, [])

  useEffect(() => {
    // poll csv status if in progress
    if (isDisabled) {
      return
    }

    let pollStatus = null

    const setCsvStatus = async () => {
      const status = await _getCsvStatus()
      setStatus(status)
    }

    if (
      reportType === reportTypes.CSV &&
      inProgressReportStatuses.includes(status)
    ) {
      pollStatus = setInterval(async () => {
        if (
          status === reportStatuses.generated ||
          status === reportStatuses.notGenerated
        ) {
          clearInterval(pollStatus)
          return
        }

        await setCsvStatus()
      }, 5000)
    }

    return () => pollStatus && clearInterval(pollStatus)
  }, [reportType, status, _getCsvStatus, isDisabled])

  const generateReport = useCallback(
    async ({ batchName, projectName, selectedRespondents, noDownload }) => {
      try {
        const respondentIds =
          selectedRespondents && selectedRespondents.map((resp) => resp.id)

        const reportResponse = await _getReport({
          url,
          respondentIds,
        })
        !_isSuccessResponse(reportResponse) &&
          (await _throwResponseError(reportResponse))

        if (reportResponse.status === 202) {
          setStatus(reportStatuses.processing)
        } else {
          setStatus(reportStatuses.generated)

          !noDownload &&
            _downloadReport({
              reportResponse,
              selectedRespondents,
              batchName,
              projectName,
            })
          handleSuccess({
            selectedRespondents: selectedRespondents,
          })
        }
      } catch (error) {
        handleError(error)
      }
    },
    [_getReport, url, _downloadReport, handleSuccess]
  )

  const _getReport = useCallback(async ({ url, respondentIds }) => {
    const token = await getAccessToken()
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Token ' + token,
        'Content-Type': 'application/json',
      }),
      ...(respondentIds && {
        body: JSON.stringify({ respondents: respondentIds }),
      }),
    })

    return response
  }, [])

  function _isSuccessResponse(response) {
    return [200, 201, 202, 203, 204].includes(response.status)
  }

  async function _throwResponseError(response) {
    const errorBody = await response.json()
    throw {
      code: response.status,
      statusText: response.status + ' ' + response.statusText,
      message: errorBody,
    }
  }

  const _downloadReport = useCallback(
    async ({ reportResponse, selectedRespondents, batchName, projectName }) => {
      let downloadItem
      let backendFilename
      if (reportType === reportTypes.CSV) {
        const { download_url } = await reportResponse.json()
        const response = await fetch(download_url)
        const blob = await response.blob()
        downloadItem = new Blob([blob], { type: 'text/csv' })
      } else if (selectedRespondents.length === 1) {
        const result = await reportResponse.json()
        backendFilename = result.filename
        downloadItem = await fetch(result.url).then((r) => r.blob())
      } else {
        downloadItem = await reportResponse.blob()
      }

      function getReportFileName({
        selectedRespondents,
        batchName,
        projectName,
      }) {
        const removeSpaces = (str) => str.replace(/\s/g, '_')
        const date = standardDate(new Date())

        let testName = testId
        if (testId === testIdentifiers.NEOB) {
          testName = 'NEO-PI-3_BRB'
        } else if (testId === testIdentifiers.NEOC) {
          testName = 'NEO-PI-3_BRC'
        } else if (testId === testIdentifiers.PRFR_OPT) {
          testName = 'SIGMA_PERSONALITY'
        }

        if (reportType === reportTypes.CSV) {
          return removeSpaces(
            `${projectName}_${batchName}_${testName}_${date}.csv`
          )
        }
        if (selectedRespondents.length === 1) {
          return removeSpaces(backendFilename)
        }
        if (selectedRespondents.length > 1) {
          return removeSpaces(
            `${projectName}_${batchName}_${testName}_${date}.zip`
          )
        }
      }

      const fileName = getReportFileName({
        selectedRespondents,
        batchName,
        projectName,
      })

      saveAs(downloadItem, fileName)
    },
    [reportType, testId]
  )

  const handleSuccess = useCallback(
    async ({ selectedRespondents }) => {
      const successMessage =
        reportType === reportTypes.CSV || selectedRespondents.length > 1
          ? 'Successfully generated reports/exports!'
          : 'Successfully generated a report!'

      const refetchedRespondents = await refetchRespondents()
      const testCompletedRespondents = refetchedRespondents.data.filter(
        (respondent) =>
          respondent.batchTest_Test_InternalTestIdentifier === testId &&
          respondent.status === 'complete'
      )
      const creditsNotRedeemed = testCompletedRespondents.filter(
        (resp) => !resp.isCreditRedeemed
      )

      if (creditsNotRedeemed.length > 0) {
        showNotification({
          length: 4000,
          type: 'error',
          children: () => (
            <span className='text'>
              {`You don't have sufficient credits. ${
                testCompletedRespondents.length - creditsNotRedeemed.length
              } / ${
                testCompletedRespondents.length
              } assessments have been scored. Please `}
              <span className='link'>
                <Link href='/admin/org/billing'>purchase credits</Link>
              </span>
              {' to resume scoring.'}
            </span>
          ),
        })
      } else {
        showNotification({
          type: 'success',
          message: successMessage,
        })
      }

      if (reportType !== 'pdf') return
      // Post successful download to server for display in batch table row
      try {
        const testUUID = selectedRespondents?.[0]?.assessmentSet?.find(
          (assessment) => assessment?.test?.internalTestIdentifier === testId
        )?.test?.id
        const respondentIds = selectedRespondents.reduce(
          (respondentIds, currentRespondent) => {
            respondentIds.push(currentRespondent.id)
            return respondentIds
          },
          []
        )
        const body = {
          respondents: respondentIds,
          test: testUUID,
        }
        const token = await getAccessToken()
        await post(apiUrl('reportsDownloaded'), body, { token })
        queryClient.invalidateQueries('respondents', batchId)
        refreshRespondents()
      } catch (err) {
        console.log('err', err)
      }
    },
    [reportType]
  )

  async function handleError(error) {
    console.error('ERROR: ', error)
    if (error.code === 400) {
      showNotification({
        type: 'error',
        message: `There are not enough report credits to generate reports. Please purchase report credits through the Billing Dashboard.`,
      })
    } else {
      showNotification({
        type: 'error',
        message: 'An error occurred. Please try again later.',
      })
    }
  }

  const _getCsvStatus = useCallback(async () => {
    const token = await getAccessToken()
    const response = await get(
      `${apiUrl('csvReport')}/${batchId}?test=${testId}`,
      { token }
    )
    const { status, is_credit_redeemed } = response

    if (status === 'completed' && is_credit_redeemed) {
      return reportStatuses.generated
    }
    if (inProgressReportStatuses.includes(status) && !response.download_url) {
      return reportStatuses.processing
    }

    if (
      (status === 'failed' && !response.download_url) ||
      response.error_message
    ) {
      return reportStatuses.failed
    }

    if (status === 'completed' && !is_credit_redeemed) {
      return reportStatuses.notGenerated
    }
  }, [batchId, testId])

  return { status, generateReport }
}
